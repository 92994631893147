// import Typogrophy from '../components/Typogrophy';
import useDocumentTitle from './../../Head/DocumentTitle'
  
const Home = () => {
  
	let hostname =window.location.hostname;
	useDocumentTitle('Available to Lease')
  return (
    <div>
        <h1>Welcome to {hostname}</h1>
        <div class=''>This domain name is available to rent for the duration of your campaign. If you are interested in this domain name, please contact us at <a href={"mailto:info@" + hostname} target='_BLANK' rel='noreferrer' >info@{hostname}</a>.</div>
    </div>
  );
};
  
export default Home;
