// import { FaBars } from 'react-icons/fa';

import * as React from "react";
import styled from 'styled-components';
import logo from '../../assets/icons/for-rent.svg';

export const Nav = styled.nav``;

export const NavBar =  (props) => {
	return (
		<nav class="NAVIT">
			{props.children}
		</nav>
	);
}
export class MenuLink extends React.Component {
	render() {
		return (
			// <div className="NavLinkCust">
				<a class='menu-link' href={this.props.to}>{this.props.name}</a>
			// </div>
		);
	}
}

export class MenuLogo extends React.Component {
	render() {
		return (
			<a class='menu-link logo-link' href="/">
				<img src={logo} className="main-logo" alt="logo" />
			</a>
		);
	}
}


export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -24px;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;
/* Third Nav */
/* justify-content: flex-end;
width: 100vw; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtnLink = styled.a`
border-radius: 4px;
background: #808080;
padding: 10px 22px;
color: #000000;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
/* Second Nav */
margin-left: 24px;
&:hover {
	transition: all 0.2s ease-in-out;
	background: #fff;
	color: #808080;
}
`;
