import { BrowserRouter as Router, Routes, Route}
from 'react-router-dom'

	import Home from '../Page/Home';
	// import Blogs from '../Page/blogs';
	// import SignUp from '../Page/signup';
	// import Contact from '../Page/contact';
	
export default function content() { 
	return (
		<Router>
		<main>
		  <Routes>
			  <Route exact path='/' element={<Home />} />
			  {/* <Route path='/contact' element={<Contact/>} /> */}
			  {/* <Route path='/blogs' element={<Blogs/>} /> */}
			  {/* <Route path='/sign-up' element={<SignUp/>} /> */}
		  </Routes>
		</main>
	  </Router>
	);
  }
